import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="TeamHäftet" />
    <h1>TeamHäftet</h1>
    <p>Automation is king</p>
  </Layout>
)

export default IndexPage
